import React, {Component} from "react";
import {AppBar, IconButton, Toolbar, Typography} from "@material-ui/core";
import {Menu as MenuIcon} from "@material-ui/icons";
import clsx from 'clsx';

import SearchBar from "./SearchBar";

export default class TopAppBar extends Component {
  render() {
    const {classes, drawerOpen, searchKey, handleSearchInput, title} = this.props;

    return (
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >
        <Toolbar>
          <IconButton
            href={undefined}
            color="inherit"
            aria-label="open drawer"
            onClick={this.props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6" noWrap
            style={{flexGrow: 1, textAlign: "left"}}
          >
            {title}
          </Typography>
          <SearchBar
            searchKey={searchKey}
            handleSearch={handleSearchInput}
            onSearch={this.props.handleSearchTasks}
          />
        </Toolbar>
      </AppBar>
    )
  }
}