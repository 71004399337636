import React from "react";
import moment from "moment";
import {AddCircle as AddCircleIcon, Alarm as AlarmIcon, Assignment as AssignmentIcon} from "@material-ui/icons";

import ScheduleTaskActions from "../components/ScheduleTaskActions";

const ScheduleTask = {
    name: 'scheduleTask',
    baseUrl: '/schedule-tasks',
    title: 'Schedule Tasks',
    icon: <AlarmIcon />,
    items: [
        {title: 'List Tasks', icon: <AssignmentIcon />, action: "/schedule-tasks"},
        {title: 'Create Task', icon: <AddCircleIcon />, action: "/schedule-tasks/create"},
    ],
    fields: [
        {
            "name": "task_id", 
            "label": "Task ID",
            "display": ["list"]
        },
        {
            "name": "interval", 
            "label": "Interval",
            "type": "number",
            "validates": ["integer"],
        },
        {
            "name": "exchange", 
            "label": "Exchange",
            "type": "text",
            "validates": ["notEmpty"]
        },
        {
            "name": "message", 
            "label": "Message",
            "type": "js"
        },
        {
            "name": "timestamp", 
            "label": "Start Time",
            "type": "datetime",
            "timestamp": true,
            "render": function (value) {
                return value ? moment(value * 1000).format('YYYY/MM/DD hh:mm A') : '-';
            }
        },
        {
            "name": "_actions",
            "label": "Actions",
            "type": "hidden",
            "render": function (value, item) {
                return <ScheduleTaskActions task={item} name="scheduleTask" baseUrl="/schedule-tasks" />
            }
        },
    ]
};

export default ScheduleTask;