import {AddCircle as AddCircleIcon, ListAlt as ListAltIcon, People as PeopleIcon} from "@material-ui/icons";
import React from "react";

const client = {
  name: 'clients',
  title: 'Clients',
  baseUrl: '/clients',
  createUrl: '/clients/create',
  icon: <PeopleIcon />,
  items: [
    {title: 'List Clients', icon: <ListAltIcon />, action: "/clients"},
    {title: 'Create Client', icon: <AddCircleIcon />, action: "/clients/create"},
  ],
  fields: [
    {
      "name": "id",
      "label": "ID"
    },
    {
      "name": "username",
      "label": "Username",
      "type": "text",
      "validates": ["notEmpty"]
    },
    {
      "name": "password",
      "label": "Password",
      "type": "password",
      "validates": ["notEmpty"]
    },
    {
      "name": "company",
      "label": "Company Name",
      "type": "text",
      "validates": ["notEmpty"]
    },
    {
      "name": "name",
      "label": "Contact Name",
      "type": "text",
      "validates": ["notEmpty"]
    },
    {
      "name": "email",
      "label": "Email",
      "type": "email",
      "validates": ["notEmpty", "email"]
    },
    {
      "name": "phone_number",
      "label": "Phone Number",
      "type": "text",
      "validates": ["notEmpty"]
    },
  ]
};

export default client;