import React, { Component } from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import clsx from 'clsx';
import _ from "lodash";
import {IconButton, Box, Button, CircularProgress, Typography, Container, Snackbar} from "@material-ui/core";

import styles from "./styles";
import FormRender from "../../utils/FormRender";
import {Close as CloseIcon} from "@material-ui/icons";

class EditContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noticeShown: false,
    }
  }

  componentDidMount() {
    const {match, schema} = this.props;
    const {name, baseUrl} = schema;
    const {id} = match.params;
    const store = this.props.store[name];

    store.getItem(baseUrl + '/' + id);
  }

  handleNoticeClose = () => {
    this.setState({noticeShown: false});
  };

  submit = () => {
    const {baseUrl, name, fields, editUrl} = this.props.schema;
    const {id} = this.props.match.params;
    const store = this.props.store[name];
    const editableFields = fields.filter(field => field.editable !== false);

    store.editItem((editUrl ? editUrl : baseUrl) + '/' + id, editableFields, () => this.setState({noticeShown: true}));
  };

  render() {
    const {noticeShown} = this.state;
    const {classes, schema} = this.props;
    const {name, baseUrl, fields} = schema;
    const store = this.props.store[name];
    const {loading, form, formErrors, fieldErrors, submitting} = store;

    if (loading) {
      return <CircularProgress color="primary" />;
    }

    if (!form) {
      return <Typography variant="button">Item not found.</Typography>;
    }

    const editableFields = fields.filter(field => field.editable !== false);
    const formData = {fields: editableFields, layout: schema.editLayout};

    return (
      <Container className={clsx("text-left", classes.container)}>
        <Typography variant="h5" component="h3">Edit User Info</Typography>
        {FormRender.renderLayout(formData, store)}
        {formErrors &&
        <Typography color="error">
          <ul>
            {formErrors.map(error => <li>{_.capitalize(error)}.</li>)}
          </ul>
        </Typography>}

        <Box style={{margin: "20px 0"}}>
          <Box component="span" className={classes.buttonWrapper}>
            <Button href={undefined}
                    disabled={!_.isEmpty(fieldErrors) || !_.isEmpty(formErrors) || submitting}
                    className={classes.buttonSpace}
                    onClick={this.submit}
                    variant="contained" color="primary"
            >
              Submit
            </Button>
            {submitting && <CircularProgress color="primary" size={24} className={classes.buttonLoading} />}
          </Box>
          <Button href={undefined} variant="contained">
            <NavLink to={baseUrl} style={{textDecoration: 'none', color: 'inherit'}}>
              Cancel
            </NavLink>
          </Button>
        </Box>

        <Snackbar
          anchorOrigin={{vertical: "bottom", horizontal: "right"}}
          open={noticeShown}
          autoHideDuration={3000}
          onClose={this.handleNoticeClose}
          message={<span>Updated successfully!</span>}
          action={
            <IconButton
              href={undefined}
              color="inherit"
              onClick={this.handleNoticeClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Container>
    );
  }
}

const EditContainerCompose = compose(
  inject('store'),
  observer
) (EditContainer);

export default withStyles(styles)(withRouter(EditContainerCompose));