import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import {IconButton, Box, Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar} from "@material-ui/core";
import {Close as CloseIcon, Delete as DeleteIcon, Edit as EditIcon} from "@material-ui/icons";

import styles from './styles';

class ScheduleTaskActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
      noticeShown: false,
      taskSelected: null,
      taskDeleted: null,
    }
  }

  handleDialogOpen = (task) => () => {
    this.setState({
      dialogOpened: true,
      taskSelected: task,
    })
  };

  handleDialogClose = (confirm = false) => () => {
    if (confirm) {
      this.onDeleteItem(this.state.taskSelected);
    }

    this.setState({
      dialogOpened: false,
      taskSelected: null,
    });
  };

  onDeleteItem = (item) => {
    const {baseUrl, name} = this.props;
    const store = this.props.store[name];

    store.deleteItem(baseUrl + "/" + item.task_id, item.task_id);
    this.setState({
      noticeShown: true,
      taskDeleted: item,
    });
  };

  handleNoticeClose = () => {
    this.setState({noticeShown: false, taskDeleted: null});
  };

  render() {
    const {dialogOpened, noticeShown, taskSelected, taskDeleted} = this.state;
    const {task} = this.props;

    return (
      <Box>
        <IconButton
          href={undefined}
          aria-label="delete"
          onClick={this.handleDialogOpen(task)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          href={undefined}
          aria-label="edit"
          disabled
          onClick={() => null}
        >
          <EditIcon fontSize="small" />
        </IconButton>

        <Dialog
          open={dialogOpened}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent dividers>
            <p>You are about to delete this task:</p>
            {taskSelected && (
              <ul>
                <li>ID: <strong>{taskSelected.task_id}</strong></li>
                <li>Exchange: <strong>{taskSelected.exchange}</strong></li>
                <li>Message: <strong>{taskSelected.message}</strong></li>
              </ul>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              href={undefined}
              onClick={this.handleDialogClose()}
            >
              Cancel
            </Button>
            <Button
              href={undefined}
              onClick={this.handleDialogClose(true)}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{vertical: "bottom", horizontal: "left"}}
          open={noticeShown}
          autoHideDuration={3000}
          onClose={this.handleNoticeClose}
          message={<span>Deleted task ID: <em>{taskDeleted && taskDeleted.task_id}</em></span>}
          action={
            <IconButton href={undefined}
                        color="inherit"
                        onClick={this.handleNoticeClose}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </Box>
    );
  }
}

const ScheduleTaskActionsCompose = compose(
  inject('store'),
  observer
) (ScheduleTaskActions);

export default withStyles(styles)(ScheduleTaskActionsCompose);