import React, {Component} from "react";
import clsx from 'clsx';
import {NavLink} from "react-router-dom";
import {
  Collapse, Divider, Drawer, IconButton,
  List, ListItem, ListItemIcon, ListItemText, Tooltip
} from "@material-ui/core";
import {
  ChevronLeft as ChevronLeftIcon,
  ExpandLess,
  ExpandMore,
  StarBorder as StarIcon,
} from "@material-ui/icons";

export default class SideDrawer extends Component {
  render() {
    const {
      drawerOpen, classes, menuOpen, openDrawer, closeDrawer,
      toggleMenu, menuItems,
    } = this.props;

    return (
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
        open={drawerOpen}
      >
        <div className={classes.toolbar}>
          <h3 style={{margin: 0, padding: 15}}>Menu</h3>
          <IconButton href={undefined} onClick={closeDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider component="div" />
        <List component="div">
          {menuItems.map((menuItem, index) => (
            <div key={index}>
              <Tooltip title={menuItem.title} placement="right" enterDelay={200}>
                <ListItem
                  button
                  component="div"
                  onClick={toggleMenu(menuItem.name)}
                  selected={menuItem.isSelected}
                >
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText primary={menuItem.title} />
                  {menuOpen === menuItem.name ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              </Tooltip>
              <Collapse in={menuOpen === menuItem.name} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {menuItem.items.map((item, idx) => {
                    return (
                      <NavLink key={idx} to={item.action}>
                        <Tooltip title={item.title} placement="right" enterDelay={200}>
                          <ListItem
                            button
                            component="div"
                            className={classes.nested}
                          >
                            <ListItemIcon>{item.icon || <StarIcon />}</ListItemIcon>
                            <ListItemText primary={item.title} />
                          </ListItem>
                        </Tooltip>
                      </NavLink>
                    )
                  })}
                </List>
              </Collapse>
            </div>
          ))}
        </List>
      </Drawer>
    )
  }
}