import { create } from 'apisauce';
import CrudStore from './CrudStore';
import UserStore from "./UserStore";

class AppStore {
    constructor() {
      this.api = create({
        baseURL: `${process.env.REACT_APP_API_ROOT}`,
        withCredentials: true
      });

      this.api.addResponseTransform(response => {
        // console.log('response is: ', response);
        if (response.status === 401 || response.status === 403) {
          window.location.href = `${process.env.REACT_APP_OAUTH_URL}/login?next=${encodeURI(window.location.href)}`;
        }
      });

      this.scheduleTask = new CrudStore(this);
      this.shipments = new CrudStore(this);
      this.drivers = new CrudStore(this);
      this.clients = new CrudStore(this);
      this.users = new CrudStore(this);
      this.userStore = new UserStore(this);
    }


}

export default AppStore;