import React, { Component } from 'react';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core";

import appStyles from "./styles";
import TopAppBar from "../components/TopAppBar";
import ListContainer from './crud/List';
import SideDrawer from "../components/SideDrawer";
import CreateContainer from './crud/Create';
import EditContainer from './edit';
import schemas from "../schemas";

class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      menuOpen: null,
      searchKey: '',
    }
  }

  handleDrawerOpen = () => {
    this.setState({drawerOpen: true});
  };

  handleDrawerClose = () => {
    this.setState({drawerOpen: false});
  };

  subMenuToggle = (menuName) => () => {
    if (!this.state.menuOpen)
      this.setState({menuOpen: menuName});
    else {
      this.setState({menuOpen: null});
    }
  };

  handleSearchInput = (value) => {
    this.setState({searchKey: value});
  };

  handleSearchTasks = (key) => {
    const {store, schema} = this.props;
    const {name, baseUrl} = schema;

    store[name].searchItem(key, baseUrl);
  };

  render() {
    const {classes, schema, view, title} = this.props;
    const {drawerOpen, menuOpen, searchKey} = this.state;
    const MENU_ITEMS = schemas.map(sc => {
      sc.isSelected = window.location.href.includes(sc.baseUrl);
      return sc;
    });

    return (
      <div className={classes.root}>
        <TopAppBar
          classes={classes}
          searchKey={searchKey}
          handleSearchInput={this.handleSearchInput}
          handleSearchTasks={this.handleSearchTasks}
          handleDrawerOpen={this.handleDrawerOpen}
          drawerOpen={drawerOpen}
          title={title}
        />
        <SideDrawer
          drawerOpen={drawerOpen}
          classes={classes}
          menuOpen={menuOpen}
          toggleMenu={this.subMenuToggle}
          menuItems={MENU_ITEMS}
          closeDrawer={this.handleDrawerClose}
          openDrawer={this.handleDrawerOpen}
        />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {(!view || view === 'list') && <ListContainer schema={schema} searchKey={searchKey} />}
          {view === 'create' && <CreateContainer schema={schema} />}
          {view === 'edit' && <EditContainer schema={schema} />}
        </main>
      </div>
    )
  }
}

const MainCompose = compose(
  inject("store"),
  observer
) (MainContainer);

export default withStyles(appStyles)(MainCompose);