import React, { Component } from 'react';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import clsx from "clsx";
import {NavLink, Route, Switch, withRouter} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import {Container, Typography, Button, Box, CircularProgress, DialogActions, Dialog,DialogTitle, DialogContent } from '@material-ui/core';

import FormRender from '../../utils/FormRender';
import {styles} from './CreateStyle';

import _ from 'lodash';

class CreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpened: false,
      fetchedData: {},
    }
  }
  handleOpenDialog = (isOpen = false) => () => {
    this.setState({
      dialogOpened: isOpen,
    })
  };

  componentDidMount() {
    this.props.schema.fields.forEach(f => {
      if (!!f.fetchData) {
        f.fetchData.then(data => this.setState(prevState => ({
          fetchedData: {
            ...prevState.fetchedData,
            [f.name]: data
          }
        })));
      }
      if(f.name === 'disable_branch_setup') {
        const { name } = this.props.schema;
        const store = this.props.store[name];
        _.set(store.form, 'disable_branch_setup', true);
      }
    })
  }

  create = () => {
    const {baseUrl, name, fields, createUrl, isCreatedPopup} = this.props.schema;
    const store = this.props.store[name];
    if (isCreatedPopup) {
      store.createItem(createUrl ? createUrl : baseUrl, fields, this.handleOpenDialog(true) );
    } else {
      store.createItem(createUrl ? createUrl : baseUrl, fields);
    }
  };

  cancel = () => {

  };

  render() {
    const {fetchedData} = this.state;
    const {classes} = this.props;
    const {name, baseUrl, fields} = this.props.schema;
    const store = this.props.store[name];
    const {formErrors, fieldErrors, submitting} = store;

    // build the form to render
    const renderFields = fields.map(f => {
      if (!!f.fetchData && !!fetchedData[f.name]) {
        f.options = fetchedData[f.name];
      }
      return f;
    })
    const form = {fields};

    return <Container className={clsx("text-left", classes.root)}>
        <Typography variant="h5" component="h3">
            Create
        </Typography>
        {FormRender.renderLayout(form, store)}
        {formErrors &&
        <Typography color="error">
          <ul>
            {formErrors.map(error => <li>{_.capitalize(error)}.</li>)}
          </ul>
        </Typography>}

        <div style={{margin: "20px 0"}}>
          <Box component="span" className={classes.buttonWrapper}>
            <Button href={undefined}
                    disabled={!_.isEmpty(fieldErrors) || !_.isEmpty(formErrors) || submitting}
                    className={classes.buttonSpace}
                    onClick={this.create}
                    variant="contained" color="primary"
            >
              Create
            </Button>
            {submitting && <CircularProgress color="primary" size={24} className={classes.buttonLoading} />}
          </Box>
          <Button href={undefined} onClick={this.cancel} variant="contained">
            <NavLink to={baseUrl} style={{textDecoration: 'none', color: 'inherit'}}>
              Cancel
            </NavLink>
          </Button>
        </div>
        <Dialog
          open={this.state.dialogOpened}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>Created Successfully</DialogTitle>
          <DialogActions>
            <Button
              href={undefined}
              onClick={this.handleOpenDialog(false)}
              color="primary"
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
    </Container>
  }
}

const CreateCompose = compose(
  inject('store'),
  observer
) (CreateComponent);

export default withRouter(withStyles(styles)(CreateCompose));