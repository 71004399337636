import {AddCircle as AddCircleIcon, DriveEta as DriverIcon, ListAlt as ListAltIcon} from "@material-ui/icons";
import React from "react";
import { getRegions } from "../stores/api";

const driver = {
    name: 'drivers',
    baseUrl: '/drivers',
    createUrl: '/drivers/create',
    title: 'Drivers',
    icon: <DriverIcon />,
    items: [
        {title: 'List Driver', icon: <ListAltIcon />, action: "/drivers"},
        {title: 'Create Driver', icon: <AddCircleIcon />, action: "/drivers/create"},
    ],
    fields: [
        {
            "name": "id", 
            "label": "ID"
        },
        {
            "name": "username", 
            "label": "Username",
            "type": "text",
            "validates": ["notEmpty"]
        },
        {
            "name": "password", 
            "label": "Password",
            "type": "password",
            "validates": ["notEmpty"]
        },
        {
            "name": "first_name", 
            "label": "First Name",
            "type": "text",
            "validates": ["notEmpty"]
        },
        {
            "name": "last_name", 
            "label": "Last Name",
            "type": "text",
            "validates": ["notEmpty"]
        },
        {
            "name": "email", 
            "label": "Email",
            "type": "email",
            "validates": ["notEmpty", "email"]
        },
        {
            "name": "phone_number", 
            "label": "Phone Number",
            "type": "text",
            "validates": ["notEmpty"]            
        },
        {
          "name": "region_code", 
          "label": "Region",
          "validates": ["notEmpty"],
          "type": "select",
          "fetchData": getRegions().then(res => {
            const options = res.data.map(rg => ({
            label: rg.properties.code, value: rg.properties.code
            }))
            return [
            {label: 'Region', value: ''},
            ...options
            ]
          }),
          "options": [
            {label: 'Region', value: ''},
          ],
          "render": function (value) {
            return value ? value.trim().replace(/[\s]/gi, "/") : '';
          },
          "hide": true,      
        },
        {
          "name": "zipcode", 
          "label": "Zipcode",
          "type": "text",
          "validates": ["notEmpty", "patternZipcode"],
          "hide": true,        
        },
        {
          "name": "disable_branch_setup", 
          "label": "Branch Setup",
          "type": "select",
          "validates": ["notEmpty"],
          "options": [
            {label: 'True', value: true},
            {label: 'False', value: false},
          ],
          "hide": true,      
        },
    ]
};

export default driver;