import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {IconButton, Box, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button} from "@material-ui/core";
import {LocalOffer as LocalOfferIcon, Edit as EditIcon} from "@material-ui/icons";
import EditExternalId from "./EditExternalId";

class UserActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editExternalIdDialog: false,
    };

    this.handleDialogClose = this.handleDialogClose.bind(this);
  }

  handleDialogClose() {
    this.setState({editExternalIdDialog: false});
  }

  render() {
    const {editExternalIdDialog} = this.state;
    const {history, user} = this.props;

    return (
      <Box style={{whiteSpace: 'nowrap'}}>
        <Tooltip title="Edit User">
          <IconButton
            href={undefined}
            aria-label="edit"
            onClick={() => history.push(`users/${user.id}/edit`)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Change External ID">
          <IconButton
            href={undefined}
            aria-label="change external id"
            onClick={() => this.setState({editExternalIdDialog: true})}
          >
            <LocalOfferIcon fontSize="small" />
          </IconButton>
        </Tooltip>

        <Dialog open={editExternalIdDialog}>
          <EditExternalId user={user} onClose={this.handleDialogClose} />
        </Dialog>
      </Box>
    )
  }
}

export default withRouter(UserActions);