import React from 'react';
import {Provider} from 'mobx-react';

import './App.css';
import RootContainer from './containers';

import AppStore from './stores/AppStore';

function App() {
  return (
    <div className="App">
      <Provider store={new AppStore()}>
        <RootContainer />
      </Provider>      
    </div>
  );
}

export default App;
