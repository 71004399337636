import React, { Fragment, Component } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField
} from "@material-ui/core";
import {compose} from "recompose";
import {inject, observer} from "mobx-react";

class EditExternalId extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newExternalId: undefined,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {store, user} = this.props;

    store.userStore.getExternalId(user.id);
  }

  handleSubmit() {
    const {store, user} = this.props;

    store.userStore.updateExternalId(user.id, this.state.newExternalId, (res) => {
      if (res.status === 200) {
        this.props.onClose();
      }
    });
  }

  render() {
    const {newExternalId} = this.state;
    const {user, onClose, store} = this.props;
    const {externalId, loading} = store.userStore;

    if (loading) {
      return (
        <Fragment>
          <DialogTitle>Change external ID for user <em>{user.username}</em> [{user.id}]</DialogTitle>
          <DialogContent>
            <LinearProgress color="primary" />
          </DialogContent>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <DialogTitle>Change external ID for user <em>{user.username}</em> [{user.id}]</DialogTitle>
        <DialogContent>
          <Box>
            <ul>
              <li><span>Current external ID:</span> <strong>{externalId || 'N/A'}</strong></li>
              <li><span>Current scopes:</span> <strong>{user._scopes}</strong></li>
            </ul>
            <TextField
              label="External ID"
              placeholder="Enter new external ID"
              type="number"
              min={0}
              fullWidth
              value={newExternalId}
              onChange={(e) => this.setState({newExternalId: e.target.value})}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            href={undefined}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            href={undefined}
            onClick={this.handleSubmit}
            color="primary"
          >
            Set
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

const EditExternalIdCompose = compose(
  inject('store'),
  observer
) (EditExternalId);

export default EditExternalIdCompose;