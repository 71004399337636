import {action, computed, decorate, observable} from 'mobx';
import _ from 'lodash';
import validate from 'validate.js';
import {RULES} from '../constants/rules';

class CrudStore {
  constructor(appStore) {
    this.appStore = appStore;
  }

  filters = {
    page: 0,
    size: 100,
    desc: true,
    // orderBy: null
  };

  dataList = {}; // items, size, page, total
  form = {};
  submitting = false;
  loading = false;

  getItem(url, callback) {
    this.loading = true;
    this.appStore.api.get(url).then(response => {
      if (response.status === 200) {
        this.form = response.data;
        if (callback) callback(response);
      }

      this.loading = false;
    });
  }

  // form
  onChange = (field) => (event) => {
    let valueToSet = event.target.value;
    // if (field.type === 'select' && field.multiple === true) {
    //   valueToSet = valueToSet.join(" ");
    // }
    _.set(this.form, event.target.name, valueToSet);
    // reset errors from server first
    delete this.form['_errors'];

    this.checkError(field, event.target.name, event.target.value);
  };
  formatPhoneNumber = phoneNumber => {
    const numericOutput = phoneNumber.replace(/[^0-9]/g,"");
    const length = numericOutput.length;
    if( length < 10) {
      return "";
    }
    return "+1" + numericOutput.substring(length - 10);
  }
  checkError(field, name, value) {
    if (!field['validates']) return;

    const validates = field['validates'];
    const constraints = {
      [name]: _.assign({}, ..._.values(_.pick(RULES, validates)))
    };
    const result = validate(_.set({}, name, value), constraints, {format: "flat"});

    // process current error
    if (!this.form['_fieldErrors']) this.form['_fieldErrors'] = {};

    if (!result) {
      _.unset(this.form['_fieldErrors'], name);
      if (name.indexOf('.')) {
        const parentName = name.split('.').shift();
        if (_.isEmpty(this.form['_fieldErrors'][parentName])) {
          _.unset(this.form['_fieldErrors'], parentName);
        }
      }
    } else {
      _.set(this.form['_fieldErrors'], name, result);
    }
  }

  getField(name, defaut) {
    return _.get(this.form, name, defaut);
  }

  getFieldError(name) {  
    if (!name) return _.get(this.form, `_fieldErrors`);  
    return _.get(this.form, `_fieldErrors.${name}`);
  }

  get formErrors() {  
    return _.get(this.form, `_errors`);
  }

  get fieldErrors() {  
    return _.get(this.form, `_fieldErrors`);
  }

  createItem(url, fields, success, error) {
    const phoneNumber = this.getField('phone_number');
    if(phoneNumber && phoneNumber.length > 0) {
       const newPhoneNumber = this.formatPhoneNumber(phoneNumber);
        _.set(this.form, 'phone_number', newPhoneNumber);
   }
    // validate first
    if (fields && _.isEmpty(this.fieldErrors)) {
      fields.forEach(field => this.checkError(field, field.name, this.getField(field.name)));
    }

    if (!_.isEmpty(this.fieldErrors)) {
      if (error) error(this.fieldErrors);
      return;
    }

    this.submitting = true;
    const _form = {};
    const formData = new FormData();
    const fieldToOmit = ['_fieldErrors', '_errors'];
    let isMultipart = false;
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    fields.forEach(field => {
      if (['image', 'file'].includes(field.type)) {
        isMultipart = true;
      }
    })

    Object.keys(this.form).map(f => {
      const name = f.split('.');
      if(name.length > 1) {
        name.reduce((a, b) => {
          _form[a] = {..._form[a], ...{[b]: this.form[f]}};
        })
      } else {
        _form[f] = this.form[f];
      }

      if (typeof this.form[f] === 'object' && !_.isEmpty(this.form[f])) {
        Object.keys(this.form[f]).map(fk => {
          if (this.form[f][fk] instanceof File) {
            formData.append('file', this.form[f][fk]);
            fieldToOmit.push(f + '.' + fk);
            isMultipart = true;
          }
        })
      }
    });

    let data = _.omit(_form, fieldToOmit);
    if (isMultipart) {
      formData.append('data', JSON.stringify(data));
    }

    this.appStore.api.post(url,
      isMultipart ? formData : data,
      isMultipart ? config : undefined
    ).then(response => {
      if (response.status === 201) {
        if (success) success(response.data);
        window.location.href = url;
      } else {
        // get errors and set it to form
        if (response.data.errors) {
          this.form['_errors'] = _.isArray(response.data.errors) ? response.data.errors : [response.data.errors];
        }
      }
      this.submitting = false;
    }).catch( error => {
      console.log('got error is: ', error);
    });
  }

  editItem(url, fields, success, error) {
    // validate first
    if (fields && _.isEmpty(this.fieldErrors)) {
      fields.forEach(field => this.checkError(field, field.name, this.getField(field.name)));
    }

    if (!_.isEmpty(this.fieldErrors)) {
      if (error) error(this.fieldErrors);
      return;
    }

    this.submitting = true;
    const _form = {};
    const formData = new FormData();
    const fieldToOmit = ['_fieldErrors', '_errors'];
    let isMultipart = false;
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };
    Object.keys(this.form).map(f => {
      const name = f.split('.');
      if(name.length > 1) {
        name.reduce((a, b) => {
          _form[a] = {..._form[a], ...{[b]: this.form[f]}};
        })
      } else {
        _form[f] = this.form[f];
      }

      if (typeof this.form[f] === 'object' && !_.isEmpty(this.form[f])) {
        Object.keys(this.form[f]).map(fk => {
          if (this.form[f][fk] instanceof File) {
            formData.append('file', this.form[f][fk]);
            fieldToOmit.push(f + '.' + fk);
            isMultipart = true;
          }
        })
      }
    });

    if (!isMultipart) {
      fields.forEach(field => {
        if (['image', 'file', 'video'].includes(field.type)) {
          isMultipart = true;
        }
      });
    }

    let data = _.omit(_form, fieldToOmit);
    if (isMultipart) {
      formData.append('data', JSON.stringify(data));
    }

    this.appStore.api.post(url,
      isMultipart ? formData : data,
      isMultipart ? config : undefined
    ).then(response => {
      if (response.status === 200) {
        if (success) success(response.data);
      } else {
        // get errors and set it to form
        if (response.data.errors) {
          this.form['_errors'] = _.isArray(response.data.errors) ? response.data.errors : [response.data.errors];
        }
      }
      this.submitting = false;
    }).catch( error => {
      console.log('got error is: ', error);
    });
  }

  deleteItem(url, id) {
    this.appStore.api.delete(url, this.form).then(response => {
      this.dataList.items = this.dataList.items.filter(item => item.task_id !== id)
    }).catch(err => {
      console.log(err)
    });
  }

  // List
  getList(url, filter = null) {
    filter = {...this.filters, ...filter};
    this.filters = filter;

    this.appStore.api.get(url, filter).then(response => {
      if (response.status === 200) {
        this.dataList = response.data;
      }
    });
  }

  // Search
  searchItem(keyword, baseUrl, filter = null) {
    if (!keyword) {
      return this.getList(baseUrl, {page: 0});
    }

    filter = {...this.filters, ...filter};
    this.filters = filter;

    const searchApi = baseUrl + '/search/' + keyword;

    this.appStore.api.get(searchApi, filter).then(response => {
      if (response.status === 200) {
        this.dataList = response.data;
      }
    });
  }
}

decorate(CrudStore, {
  filters: observable,
  dataList: observable,
  form: observable,
  submitting: observable,
  loading: observable,
  getItem: action,
  getList: action,
  createItem: action,
  editItem: action,
  deleteItem: action,
  searchItem: action,
  formErrors: computed,
  fieldErrors: computed
});

export default CrudStore;