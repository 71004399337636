import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import MainContainer from "./MainContainer";
import ScheduleSchema from '../schemas/ScheduleTask';
import Shipments from "../schemas/Shipments";
import Driver from "../schemas/Driver";
import Client from "../schemas/Client";
import User from "../schemas/User";

class RootContainer extends Component {
    componentDidMount() {
        document.title = "Jitsu Admin"
    }

    render() {
        return (
            <Router>
                <Route exact path="/" render={() => <MainContainer schema={ScheduleSchema} title="Schedule tasks" />} />
                <Route exact path="/schedule-tasks" render={() => <MainContainer schema={ScheduleSchema} view="list" title="Schedule tasks" />} />
                <Route exact path="/schedule-tasks/create" render={() => <MainContainer schema={ScheduleSchema} view="create" title="Schedule tasks" />} />
                <Route exact path="/shipments" render={() => <MainContainer schema={Shipments} title="Shipments" />} />
                <Route exact path="/drivers" render={() => <MainContainer schema={Driver} title="Driver" />} />
                <Route exact path="/drivers/create" render={() => <MainContainer schema={Driver} view="create" title="Create driver" />} />
                <Route exact path="/clients" render={() => <MainContainer schema={Client} title="Client" />} />
                <Route exact path="/clients/create" render={() => <MainContainer schema={Client} view="create" title="Create client" />} />
                <Route exact path="/users" render={() => <MainContainer schema={User} title="User" />} />
                <Route exact path="/users/create" render={() => <MainContainer schema={User} view="create" title="Create user" />} />
                <Route exact path="/users/:id/edit" render={() => <MainContainer schema={User} view="edit" title="Edit user" />} />
            </Router>
        );
    }
}

export default RootContainer;