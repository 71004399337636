import { validate } from "validate.js";

export const RULES = {
    notEmpty: {
        presence: {allowEmpty: false}
    },
    email: {
        email: true
    },
    integer: {
        numericality: {strict: true}
    },
    patternZipcode: {
      format: {
        pattern: /^[0-9]{5}(?:-[0-9]{4})?$/,
        message: function(value, attribute, validatorOptions, attributes, globalOptions) {
          return validate.format("^%{num} is not a valid zipcode", {
            num: value
          });
        }
      },
    },
};

