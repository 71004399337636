import _ from 'lodash';
import React from 'react';
import {FormControl, FormHelperText, InputLabel, TextField, Paper, Typography, Grid, Select, MenuItem} from "@material-ui/core";
import Editor from "react-simple-code-editor";
import {highlight, languages} from "prismjs/components/prism-core";

import DateTime from '../components/datetime/datetime';
import FileInput from "../components/FileInput";

import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup'; // XML, HTML

class FormRender {
  tagMapper = {
    'TextField': TextField,
    'Select': Select,
    'DateTimePicker': DateTime,
    "Paper": Paper,
    "Typography": Typography,
    "Grid": Grid,
    "File": FileInput,
  };

  DEFAULT_TYPE = {
    text: {
      tag: "TextField",
      margin: "normal",
      variant: "outlined",
    },
    "datetime": {
      tag: "DateTimePicker",
      margin: "normal",
      variant: "outlined"
    },
    code: {
      tag: "TextField",
      margin: "dense",
      variant: "outlined",
      multiline: true,
      fullWidth: true,
      InputProps: {
        inputComponent: Editor,
        inputProps: {
          highlight: (code) => highlight(code, languages.xml),
          // value: undefined,
          onValueChange: () => null,
          padding: 10,
        }
      },
    },
    select: {
      tag: 'Select',
      variant: 'outlined',
    },
    image: {
      tag: 'File',
      fileType: 'image',
    },
    video: {
      tag: 'File',
      fileType: 'video',
    },
    file: {
      tag: 'File',
    },
  };

  defaultProps = {
    text: {
      ...this.DEFAULT_TYPE.text,
    },
    email: {
      ...this.DEFAULT_TYPE.text,
    },
    password: {
      ...this.DEFAULT_TYPE.text,
    },
    number: {
      ...this.DEFAULT_TYPE.text,
    },
    "datetime": {
      ...this.DEFAULT_TYPE.datetime,
    },
    html: {
      ...this.DEFAULT_TYPE.code,
    },
    js: _.merge(
      this.DEFAULT_TYPE.code,
      {InputProps: {
        inputProps: {
          highlight: (code) => highlight(code, languages.js)
        }
      }}
    ),
    select: {
      ...this.DEFAULT_TYPE.select,
    },
    image: {
      ...this.DEFAULT_TYPE.image,
    },
    video: {
      ...this.DEFAULT_TYPE.video,
    },
    file: {
      ...this.DEFAULT_TYPE.file,
    },
  };

  defaultLayout = [
    {
      tag: "form",
      children: [
        {
          type: "fields"
        }
      ]
    }
  ];

  testLayout = [
    {
      tag: "form",
      children: [
        {
          tag: "Grid",
          container: true,
          children: [
            {
              tag: 'Grid',
              item: true,
              xs: 6,
              children: [
                {
                  name: "username",
                  type: "field"
                }
              ]
            },
            {
              tag: "Grid",
              item: true,
              xs: 6,
              children: [
                {
                  name: "email",
                  type: "field"
                }
              ]
            },
          ]
        },
        {
          type: 'fields'
        }
      ]
    }
  ];

  getFields(layout, fields) {
    const that = this;
    return _.reduce(layout, function (result, value, key) {
      // check if value is object
      if (value instanceof Object) {
        if (value['type'] && value['type'] === 'field') {
          return result.concat([value['name']]);
        } else if (value['children']) {
          return result.concat(that.getFields(value['children'], fields));
        } else {
          return result;
        }
      } else {
        return result;
      }
    }, []);
  }

  renderField(props, formStore) {
    // get type of element first
    const defaultProps = this.defaultProps[props.type] ? this.defaultProps[props.type] : {};
    const propsToRender = _.merge(_.cloneDeep(defaultProps), props);

    // get tagName
    if (!propsToRender['tag'] || !propsToRender['name']) return null;
    const tag = propsToRender['tag'];
    const TagName = this.tagMapper[tag] ? this.tagMapper[tag]: tag;
    const fieldName = propsToRender['name'];
    // get errors
    const errors = formStore.getFieldError(fieldName);
    console.log('errors is: ', errors);

    let fieldValue = formStore.getField(fieldName, '');
    if (propsToRender['type'] === 'select' && propsToRender['multiple'] === true) {
      if (_.isString(fieldValue)) {
        fieldValue = fieldValue.split(/\s+|,/).filter(value => value !== "");
      } else if (_.isArray(fieldValue)) {
        fieldValue = fieldValue.filter(value => value !== "");
      }
    }

    return (
      <FormControl component="div" variant="outlined" key={fieldName} margin="normal" required fullWidth error={!!errors}>
        {props.type === 'select' && <InputLabel id={`input-${props.name}-label`}>{props.label}</InputLabel>}
        <TagName
          id={`input-${props.name}`}
          labelId={`input-${props.name}-label`}
          value={fieldValue}
          onChange={formStore.onChange(props)}
          children={tag === 'Select' && propsToRender['options'] && propsToRender['options'].map((option, idx) => (
            <MenuItem key={idx} value={option.value}>{[null, undefined, ''].includes(option.value) ? <em style={{opacity: 0.65}}>{propsToRender['label']}</em> : option.label}</MenuItem>
          ))}
          {..._.omit(propsToRender, ['tag', 'render', 'options', 'hide', 'sortable', 'editable'])}
        />
        {!!errors && errors.map((err, idx) => <FormHelperText key={idx} id="component-error-text">{err}</FormHelperText>)}
      </FormControl>
    )
  }

  renderElement(props, restForm, mapField, formStore, index = null) {
    // get type of element first
    const defaultProps = this.defaultProps[props.type] ? this.defaultProps[props.type] : {};
    const propsToRender = _.merge(defaultProps, props);

    if (propsToRender['type'] && propsToRender['type'] === 'field') {
      return this.renderField(mapField[props['name']], formStore);
    }

    if (propsToRender['type'] && propsToRender['type'] === 'fields') {
      return restForm;
    }

    if (!propsToRender['tag']) return null;
    const tag = propsToRender['tag'];
    const TagName = this.tagMapper[tag] ? this.tagMapper[tag]: tag;

    if (propsToRender['children']) {
      return <TagName {..._.omit(propsToRender, ['tag', 'children'])} key={index}>
        {propsToRender['children'].map((element, index) => this.renderElement(element, restForm, mapField, formStore, index))}
      </TagName>
    } else {
        return <TagName {..._.omit(propsToRender, ['tag', 'children'])} />
    }
  }

  renderLayout(form, formStore) {
    // get field in form first
    const layout = form['layout'] ? form['layout'] : this.defaultLayout;

    // convert fields to mapField
    const mapField = form['fields'].reduce((result, v) => {
      result[v['name']] = v;
      return result;
    }, {});

    // get fields first
    const fields = this.getFields(layout, form['fields']);

    // filter fields and then render fields
    const formFields = form['fields'].filter(f => !fields.includes(f.name));
    const restForm = formFields.map(field => this.renderField(field, formStore));
    if (layout instanceof Array) {
      return layout.map(element => this.renderElement(element, restForm, mapField, formStore))
    } else {
      this.renderElement(layout, mapField, formStore);
    }
  }
}

export default new FormRender();