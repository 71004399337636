import {LocalShipping as LocalShippingIcon, Search as SearchIcon} from "@material-ui/icons";
import React from "react";

const Shipments = {
  name: 'shipments',
  baseUrl: '/shipments',
  title: 'Shipments',
  icon: <LocalShippingIcon />,
  items: [
    {title: 'Search shipments', icon: <SearchIcon />, action: "/shipments"},
  ],
  hidePagination: true,
  fields: [
    {"name": "_created", "label": "Created"},
    {"name": "id", "label": "Shipment ID"},
    {"name": "internal_id", "label": "Internal ID"},
    {"name": "job_id", "label": "Job ID"},
    {"name": "assignment_id", "label": "Assignment ID"},
    {"name": "client_id", "label": "Client ID"},
    {"name": "status", "label": "Status"},
    {"name": "customer.name", "label": "Customer name"},
    {"name": "customer.email", "label": "Customer email"},
    {"name": "customer.phone_number", "label": "Customer phone"},
    {"name": "tracking_code", "label": "Tracking code"},
    {"name": "workload", "label": "Workload"},
    {"name": "is_cancelled", "label": "Canceled", render: renderField},
    {"name": "delivery_items", "label": "Shipment note"},
    {"name": "dropoff_address.street", "label": "Dropoff street"},
    {"name": "dropoff_address.street2", "label": "Dropoff street 2"},
    {"name": "dropoff_address.city", "label": "Dropoff city"},
    {"name": "dropoff_address.state", "label": "Dropoff state"},
    {"name": "dropoff_address.zipcode", "label": "Dropoff zipcode"},
    {"name": "dropoff_address.lat", "label": "Dropoff latitude"},
    {"name": "dropoff_address.lng", "label": "Dropoff longitude"},
    {"name": "dropoff_earliest_ts", "label": "Dropoff earliest time"},
    {"name": "dropoff_latest_ts", "label": "Dropoff latest time"},
    {"name": "dropoff_note", "label": "Dropoff note"},
    {"name": "dropoff_access_code", "label": "Access code"},
    {"name": "dropoff_additional_instruction", "label": "Addition instruction"},
    {"name": "dropoff_navigation_difficulty", "label": "Navigation difficulty"},
    {"name": "dropoff_uncharted", "label": "Dropoff uncharted"},
    {"name": "service_level", "label": "Service level"},
    {"name": "service_type", "label": "Service type"},
    {"name": "pickup_address.street", "label": "Pickup street"},
    {"name": "pickup_address.street2", "label": "Pickup street 2"},
    {"name": "pickup_address.city", "label": "Pickup city"},
    {"name": "pickup_address.state", "label": "Pickup state"},
    {"name": "pickup_address.zipcode", "label": "Pickup zipcode"},
    {"name": "pickup_address.lat", "label": "Pickup latitude"},
    {"name": "pickup_address.lng", "label": "Pickup longitude"},
    {"name": "pickup_note", "label": "Pickup note"},
    {"name": "warehouse_id", "label": "Warehouse ID"},
    {"name": "tags", "label": "Tags"},
    {"name": "timezone", "label": "Timezone"},
    {"name": "delivery_proof_photo_required", "label": "Photo required", render: renderField},
    {"name": "signature_required", "label": "Signature required", render: renderField},
    {"name": "id_required", "label": "ID required", render: renderField},
    {"name": "sms_enabled", "label": "SMS enabled", render: renderField},
    {"name": "inbound_status", "label": "Inbound status"},
    {"name": "inbound_notes", "label": "Inbound note"},
    {"name": "inbound_scan_ts", "label": "Inbound scan"},
    {"name": "inbound_lock_ts", "label": "Inbound lock"},
    {"name": "rdi", "label": "RDI"},
    {"name": "parcels", "label": "Parcels", render: renderField},
  ],
};

function renderField(value) {
  if (typeof value === 'boolean') {
    return !value ? "false" : "true";
  } else if (typeof value === 'object') {
    return JSON.stringify(value);
  }
}

export default Shipments;