import {observable, action, decorate, computed} from 'mobx';

class UserStore {
  constructor(appStore) {
    this.appStore = appStore;
  }

  externalId = undefined;
  loading = false;

  getExternalId(userId, callback) {
    this.loading = true;
    this.appStore.api.get(`/users/${userId}/external-id`).then(response => {
      console.log(response);
      if (response.status === 200) {
        this.externalId = response.data;
      } else if (response.status === 404) {
        this.externalId = undefined;
      }

      this.loading = false;
      if (callback) callback(response);
    })
  }

  updateExternalId(userId, externalId, callback) {
    this.appStore.api.post(`/users/${userId}/external-id`, externalId).then(response => {
      if (callback) callback(response)
    })
  }
}

decorate(UserStore, {
  externalId: observable,
  loading: observable,
  getExternalId: action,
  updateExternalId: action,
});

export default UserStore;