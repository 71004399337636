const styles = theme => ({
  container: {
    '& textarea': {
      '&:focus': {
        outline: 'none',
      }
    }
  },
  buttonSpace: {
    margin: theme.spacing(1),
  },
  buttonWrapper: {
    position: 'relative',
  },
  buttonLoading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default styles;