import React, { Component } from 'react';
import {Box, Button, Grid, Tooltip} from "@material-ui/core";
import PropTypes from "prop-types";

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadPreviewUrl: '',
      fileName: '',
    };
    this.fileInput = null;
  }

  onUploadPreview = (e) => {
    e.preventDefault();

    if (e.target.files.length < 1) return;

    const {name, multiple, onChange, fileType} = this.props;
    const reader = new FileReader();
    const file = !multiple ? e.target.files[0] : e.target.files;

    reader.onloadend = () => {
      this.setState({
        imageUploadPreviewUrl: fileType === 'image' ? reader.result : '',
        fileName: file.name,
      })
    };

    reader.readAsDataURL(file);
    const event = {
      target: {name, value: file},
    };
    onChange(event);
  };

  onClearInput = () => {
    const {name, onChange} = this.props;

    if (this.fileInput) this.fileInput.value = '';
    this.setState({
      imageUploadPreviewUrl: '',
      fileName: '',
    });

    const event = {
      target: {name, value: null},
    };
    onChange(event);
  };

  render() {
    const {imageUploadPreviewUrl, fileName} = this.state;
    const {fileType, name, multiple, label, value} = this.props;
    let accept = '*';
    if (fileType === 'image') {
      accept = 'image/*';
    } else if (fileType === 'video') {
      accept = 'video/*';
    }

    const fileNameFromValue = value && typeof value === 'string' ? value.split('?').shift().split('/').pop() : "";

    return (
      <Box>
        <Box px={1.5} my={1.5}>{label}</Box>
        <Grid container spacing={1} alignItems="center" style={{border: '1px solid #ccc', padding: 10}}>
          <Grid item>
            <input type="file" accept={accept}
                   style={{display: 'none'}}
                   id={name}
                   name={name}
                   multiple={multiple}
                   onChange={this.onUploadPreview}
                   ref={ref => this.fileInput = ref}
            />
            <label htmlFor={name}>
              <Button variant="contained" color="primary" component="span" disableElevation>
                Choose
              </Button>
            </label>
          </Grid>
          <Grid item>
            <Button variant="contained" color="default" component="span" disableElevation onClick={this.onClearInput}>
              Clear
            </Button>
          </Grid>
          <Grid item>
            {(!!imageUploadPreviewUrl || !!value) && (
              <Tooltip arrow title={<img style={{display: 'block', width: '100%'}} src={imageUploadPreviewUrl || value} alt="POD Preview" />}>
                <img style={{display: 'block', height: 36}} src={imageUploadPreviewUrl || value} alt="Avatar Preview" />
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <Box>{fileName || fileNameFromValue || 'No file selected.'}</Box>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

FileInput.defaultProps = {
  multiple: false,
};

FileInput.propsType = {
  onChange: PropTypes.required,
  name: PropTypes.string.required,
  fileType: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.boolean,
};

export default FileInput;