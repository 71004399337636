import React, {Component, Fragment} from 'react';
import {compose} from "recompose";
import {inject, observer} from "mobx-react";
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {
  Table, TableBody, TableCell, TableHead, TableRow, Button, Typography,
  IconButton, TablePagination, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar
} from "@material-ui/core";
import {Delete as DeleteIcon, Edit as EditIcon, Close as CloseIcon} from "@material-ui/icons";
import _ from 'lodash';

import PaginationActions from "../../components/PaginationActions";

class ListComponent extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {baseUrl, name} = this.props.schema;
    const store = this.props.store[name];

    // load data
    if (baseUrl) {
      store.getList(baseUrl);
    }
  }

  handlePageChange = (e, newPage) => {
    const filters = {page: newPage};
    this.handleChangeFilters(filters);
  };

  handleRowPerPageChange = (e) => {
    const filters = {size: e.target.value, page: 0};
    this.handleChangeFilters(filters);
  };

  handleChangeFilters = (filters) => {
    const {schema, searchKey} = this.props;
    const {baseUrl, name} = schema;
    const store = this.props.store[name];

    if (!searchKey) {
      store.getList(baseUrl, filters);
    } else {
      store.searchItem(searchKey, baseUrl, filters)
    }
  };

  render() {
    const {name, fields, hideActions, hidePagination} = this.props.schema;
    const {dataList} = this.props.store[name];
    const {items, page, size, total} = dataList;

    return (
      <div>
        {(items && items.length > 0) ? (
          <Fragment>
            <Table>
              <TableHead>
                <TableRow>
                  {fields.filter(f => !f.hide).map(field =>
                    <TableCell key={field.name}>{field.label ? field.label : _.capitalize(field.name)}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              {items && (
                <TableBody>
                  {items.map((item, idx) => (
                    <TableRow key={idx} style={{backgroundColor: idx%2 === 0 && "#fafafa"}}>
                      {fields.filter(f => !f.hide).map((field, index) => {
                        const fieldValue = _.get(item, field.name);
                        const isStringField = typeof fieldValue === 'string';

                        return (
                          <TableCell key={index} style={{minWidth: isStringField && 150, maxWidth: 400, wordBreak: 'break-word'}}>
                            <div style={{maxHeight: 150, overflow: 'auto'}}>{field.render ? field.render(fieldValue, item) : fieldValue}</div>
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
            {!hidePagination && (
              <TablePagination
                component="div"
                count={total || 0}
                page={page || 0}
                rowsPerPage={size || 5}
                // rowsPerPageOptions={[2, 5, 10, 25, 50]}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowPerPageChange}
                ActionsComponent={PaginationActions}
              />
            )}
            {hidePagination && (
              <div style={{textAlign: 'left', padding: '15px 10px'}}>Total: {total || 0}</div>
            )}
          </Fragment>
        ) : ( // No data found, or the list is empty
          <Typography variant="button">No data found.</Typography>
        )}
      </div>
    )
  }
}

const ListCompose = compose(
  inject('store'),
  observer
) (ListComponent);

export default withRouter(ListCompose);