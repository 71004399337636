import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import MomentUtils from '@date-io/moment';
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import _ from 'lodash';
import moment from "moment";

class DateTime extends Component {
    handleDateChange = (date) => {
        const {name, timestamp, dateOnly, onChange} = this.props;
        if (dateOnly) {
            date.setUTCHours(12);
            date.setMinutes(0);
        }

        let value = date;

        if (timestamp) {
            value = date.getTime()/1000;
        } else {
            value = moment(date).toISOString();
        }

        const event = {
            target: {name, value}
        };

        onChange(event);
    };

    render () {
        const {variant, timestamp, value, dateOnly, timeOnly} = this.props;
        
        const props = _.omit(Object.assign({}, this.props), ['timestamp']);
        if (variant) props['inputVariant']= variant;        

        // process timestamp
        if (timestamp && value != "") {
            try {
                props['value'] = new Date(value * 1000);
            } catch (error) {
                console.error(`Invalid timestamp value: ${value}`)
                props['value'] = new Date();
                this.handleDateChange(props['value']);
            }
        } else if (value === "") {
            props['value'] = new Date();
            this.handleDateChange(props['value']);
        }

        if (dateOnly) {
            props['format'] = "yyyy/MM/dd";
        } else if (timeOnly) {
            props['format'] = "hh:mm a";
        }

        let picker = <DateTimePicker {...props} onChange={this.handleDateChange} />;

        if (dateOnly) {
            picker = <DatePicker {...props} onChange={this.handleDateChange} />;
        } else if (timeOnly) {
            picker = <TimePicker {...props} onChange={this.handleDateChange} />
        }

        return <MuiPickersUtilsProvider utils={DateFnsUtils}>
            {picker}
        </MuiPickersUtilsProvider>
    }
}

DateTime.defaultProps = {
    format: "yyyy/MM/dd hh:mm a",
    timestamp: false
};

DateTime.propsType = {
    onChange: PropTypes.required,
    variant: PropTypes.string,
    name: PropTypes.string.required,
    label: PropTypes.string,
    format: PropTypes.string.required,
    timestamp: PropTypes.bool.required
};

export default DateTime;