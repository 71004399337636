import {Search as SearchIcon, Person as PersonIcon, PersonAdd as PersonAddIcon} from "@material-ui/icons";
import React from "react";
import moment from "moment";

import UserActions from "../components/UserActions";
import {getRegions} from "../stores/api";

const user = {
  name: 'users',
  title: 'Users',
  baseUrl: '/users',
  createUrl: '/users/create',
  editUrl: '/users/user-info',
  icon: <PersonIcon />,
  isCreatedPopup: true,
  items: [
    {title: 'Search Users', icon: <SearchIcon />, action: "/users"},
    {title: 'Create User', icon: <PersonAddIcon />, action: "/users/create"},
  ],
  hidePagination: true,
  fields: [
    {
      "name": "id",
      "label": "ID"
    },
    {
      "name": "username",
      "label": "User Name",
      "type": "text",
      "editable": false,
      "validates": ["notEmpty"]
    },
    {
      "name": "password",
      "label": "Password",
      "type": "password",
      "hide": true,
      "editable": false,
      "validates": ["notEmpty"],
    },
    {
      "name": "email",
      "label": "Email",
      "type": "email",
      "editable": false,
      "validates": ["notEmpty"]
    },
    {
      "name": "phone_number",
      "label": "Phone Number",
      "type": "text",
      "editable": false,
      "validates": ["notEmpty"]
    },
    {
      "name": "_scopes",
      "label": "Scope",
      "type": "select",
      "editable": false,
      "validates": ["notEmpty"],
      "multiple": true,
      "options": [
        {label: 'Scopes', value: ''},
        {label: 'Dispatcher', value: 'dispatcher'},
        {label: 'Warehouse Manager', value: 'warehouse-manager'},
        {label: 'Warehouse Staff', value: 'warehouse-staff'},
      ],
      "render": function (value) {
        return value ? value.trim().replace(/[\s]/gi, "/") : '';
      }
    },
    {
      "name": "warehouses",
      "label": "Warehouse Regions",
      "type": "select",
      "editable": false,
      "multiple": true,
      "fetchData": getRegions().then(res => {
        const options = res.data.map(rg => ({
          label: rg.properties.code, value: rg.properties.code
        }))
        return [
          {label: 'Warehouse Regions', value: ''},
          ...options
        ]
      }),
      "options": [
        {label: 'Warehouse Regions', value: ''},
      ],
      "render": function (value) {
        return value ? value.trim().replace(/[\s]/gi, "/") : '';
      }
    },
    {
      "name": "user_info.first_name",
      "label": "First Name",
      "type": "text",
      "validates": ["notEmpty"]
    },
    {
      "name": "user_info.middle_name",
      "label": "Middle Name",
      "type": "text",
    },
    {
      "name": "user_info.last_name",
      "label": "Last Name",
      "type": "text",
      "validates": ["notEmpty"]
    },
    {
      "name": "user_info.nickname",
      "label": "Nick Name",
      "type": "text",
    },
    {
      "name": "user_info.gender",
      "label": "Gender",
      "type": "select",
      'options': [
        {label: '', value: ''},
        {label: 'Male', value: 'male'},
        {label: 'Female', value: 'female'},
        {label: 'Other', value: 'other'},
      ],
      "validates": []
    },
    {
      "name": "user_info.birthday",
      "label": "Birthday",
      "type": "datetime",
      "dateOnly": true,
      "render": function (value) {
        return value ? moment(value).format('YYYY-MMM-DD') : '';
      }
    },
    {
      "name": "user_info.avatar_url",
      "label": "Avatar",
      "type": "image",
      "validates": [],
      "render": function (value) {
        return value ? <img src={value} alt="avatar" style={{maxHeight: 50}} /> : '';
      }
    },
    {
      "name": "user_info.home_street",
      "label": "Home Street",
      "type": "text"
    },
    {
      "name": "user_info.home_street2",
      "label": "Home Street 2",
      "type": "text"
    },
    {
      "name": "user_info.home_city",
      "label": "Home City",
      "type": "text"
    },
    {
      "name": "user_info.home_state",
      "label": "Home state",
      "type": "text"
    },
    {
      "name": "user_info.home_zipcode",
      "label": "Home Zipcode",
      "type": "text"
    },
    {
      "name": "user_info.job_position",
      "label": "Job position",
      "type": "text"
    },
    {
      "name": "user_info.description",
      "label": "Description",
      "type": "text"
    },
    {
      "name": "user_info.scanned_state_id",
      "label": "Scanned State ID",
      "type": "text",
      "multiline": true,
      "rows": 3
    },
    {
      "name": "_actions",
      "label": "Actions",
      "type": "hidden",
      "render": function (value, item) {
        return <UserActions user={item} />
      }
    },
  ],
};

export default user;